<template>
  <v-container>
    <v-form ref="form" v-model="valid" :lazy-validation="false">
      <head-title
        text="Nuevo Contravale"
        :to="{ name: 'voucher-list' }"
      ></head-title>
      <v-row>
        <v-col cols="12" md="5">
          <provider-field
            v-model="providerId"
            :required="true"
            :available="available"
            :reset="reset"
          ></provider-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="indigo"
            @click="createProvider()"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
          <company-field
            v-model="branchId"
            @value="(val) => (branchId = val)"
            required
          ></company-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-checkbox label="Agregar ID Flota" v-model="showId"></v-checkbox>
        </v-col>
        <v-col cols="12" md="5">
          <div v-if="showId">
            <v-autocomplete
              v-model="extraInfoText"
              item-text="code"
              item-value="code"
              persisten-hint
              :items="vehicleList"
              label="ID FLOTA"
              :rules="onlyRequired"
            >
            </v-autocomplete>
          </div>
          <div v-else>
            <v-text-field
              :rules="textRules"
              label="Observaciones"
              v-model="extraInfoText"
              class="mb=0"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            v-model="requestedBy"
            :rules="onlyRequired"
            label="Persona que solicita"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-for="(inline, index) in inlines" :key="index">
        <v-col cols="12" md="12">
          <v-text-field
            label="Descripción"
            :rules="textRules"
            v-model="inline.description"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="12" md="2">
          <v-btn block color="warning" @click="removeLines">Borrar</v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn block color="info" @click="addLine">Nueva Linea</v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn block color="success" @click="submit" :disabled="!valid">
            Guardar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <voucher-detail
      :dialog="dialog"
      :voucher="voucher"
      v-if="dialog"
      @close="$router.push('/vouchers/')"
    ></voucher-detail>
    <form-provider
      :dialogProvider="dialogProvider"
      :options="1"
      @close="(dialogProvider = false), updateField()"
      :title="title"
      :hidden="true"
    ></form-provider>
  </v-container>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    CompanyField: () => import("@/components/fields/CompanyField"),
    VoucherDetail: () => import("@/components/vouchers/Detail"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    FormProvider: () => import("@/components/accounting/EditProvider"),
  },
  data() {
    return {
      dialogProvider: false,
      reset: false,
      title: "",
      available: true,
      valid: false,
      options: 0,
      dialog: false,
      requestedBy: "",
      providerId: 0,
      items: [],
      branchId: 0,
      extraInfoText: "",
      vehicleList: [],
      showId: false,
      inlines: [
        {
          description: "",
        },
      ],
      //rules
      textRules: [rules.required, rules.minLength(5)],
      onlyRequired: [rules.required],
      decimalRules: [rules.decimal, rules.required, rules.minValue(0.01)],
    };
  },
  methods: {
    removeLines() {
      this.inlines = [
        {
          description: "",
        },
      ];
    },
    addLine() {
      this.inlines.push({
        description: "",
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.valid = false;
        requests
          .post("/api/vouchers/", {
            company_id: this.branchId,
            provider_id: this.providerId.id,
            observations: this.extraInfoText,
            requested_by: this.requestedBy,
            details: this.inlines,
          })
          .then((response) => {
            if (response.status == 201) {
              this.voucher = response.data.id;
              this.dialog = true;
            } else {
              console.log(response);
            }
          });
      }
    },
    createProvider() {
      this.dialogProvider = true;
      this.title = "Nuevo Proveedor";
    },
    updateField() {
      if (this.reset) {
        this.reset = false;
      } else {
        this.reset = true;
      }
    },
  },
  watch: {
    showId: function (newValue) {
      if (newValue) {
        requests.get("/api/catalogs/vehicles/").then((data) => {
          this.vehicleList = data.data.results;
        });
      }
    },
    branchId(val) {
      if (typeof val === "object") {
        this.branchId = this.branchId.id;
      }
    },
  },
};
</script>